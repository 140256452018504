<template>
  <div>
    <div class="mx-2">
      <!-- <b-breadcrumb
        class="breadcrumb pb-2 pt-1 noprint"
        :items="breadcrumb_items"
      ></b-breadcrumb> -->

      <h3 class="mt-5">
        {{ $getVisibleNames("teaching.basera", false, "RA Base") }}
        <AlternativeNameButton
          :table_name="'teaching.basera'"
          :allows_crud="allows_crud"
        ></AlternativeNameButton>
      </h3>
      <div style="display: flex">
        <div class="col" style="text-align: left">
          <ButtonMedium
            v-can="'teaching.add_basera'"
            v-if="allows_crud && user.is_superuser"
            :tooltip_text="`Crear ${$getVisibleNames(
              'teaching.basera',
              false,
              'RA Base'
            )}`"
            :text_button="`Crear`"
            :icon="'plus'"
            :variant="'primary'"
            :click_button="() => $bvModal.show(`new-ra_base-modal`)"
          >
          </ButtonMedium>
          <!-- <button
            v-can="'teaching.add_basera'"
            v-if="allows_crud && user.is_superuser"
            class="btn btn-secondary btn-sm"
            @click="$bvModal.show(`new-ra_base-modal`)"
          >
            Crear
            {{ $getVisibleNames("teaching.basera", false, "RA Base") }}
          </button> -->
        </div>
      </div>
      <div class="d-flex">
        <!-- <b-button
          variant="secondary"
          size="sm"
          class="mt-2 ml-auto mr-1 noprint"
          :disabled="disabled_button_excel"
          @click="DownloadExcel()"
        >
          {{ $getVisibleNames("teaching.basera", true, "RA Base") }}
          XLSX
        </b-button> -->
      </div>
      <!-- Filtro -->
      <div class="search noprint pl-3 pr-3">
        <div class="tag-container mt-2">
          <div
            class="style-tag"
            v-for="(tag, index) in multiple_search"
            :key="index"
          >
            <div class="d-flex">
              <div class="align-self-center">
                {{ tag.name }}
              </div>
              <b-button
                class="p-0"
                variant="none"
                v-b-tooltip.v-secondary.noninteractive="`Eliminar Filtro`"
                @click="deleteTag(index)"
              >
                <b-icon font-scale="1" style="color: white" icon="x"></b-icon>
              </b-button>
            </div>
          </div>
        </div>
        <div class="d-flex mt-1">
          <b-form-select
            class="w-25 mr-3"
            v-model="selected_filter_id"
            :options="filterOptions"
            value-field="value"
            text-field="name"
            placeholder="-- Seleccione el Filtro a Utilizar --"
          >
          </b-form-select>
          <!-- <template #first>
              <b-form-select-option :value="null"
                >-- Seleccione una Opción --</b-form-select-option
              >
            </template> -->
          <b-input-group>
            <b-input-group-prepend is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              id="search-form"
              type="search"
              v-model="input_search"
              @keydown.enter="addSearchTag"
              placeholder="Buscar..."
            >
            </b-form-input>
            <b-input-group-append>
              <b-button
                class="mr-2"
                :disabled="!input_search"
                @click="addSearchTag"
                >Buscar</b-button
              >
            </b-input-group-append>
            <b-input-group-append>
              <b-button
                :disabled="multiple_search.length == 0"
                @click="multiple_search = []"
                >Limpiar</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
      <NewGenericBTable
        :key="key_new_generic_table"
        :search_filter="false"
        :fields="ra_bases_fields"
        :pre_filter="pre_filter"
        :pre_current_page="current_page"
        @current_page="slotUpdateCurrentPage"
        :url="'/teaching/alt-base-ra/'"
      >
        <template #btn-download>
          <GenericButtonDownload
            :tooltip_text="`${$getVisibleNames(
              'teaching.basera',
              true,
              'RA Base'
            )} XLSX`"
            :click_button="DownloadExcel"
            :disabled_button="disabled_button_excel"
            :class="`ml-1`"
            :icon_excel="true"
          ></GenericButtonDownload>
        </template>
        <template #cell(egress_profile)="row">
          <template
            v-if="
              row.item.faculties_careers_and_egress_profiles &&
              row.item.faculties_careers_and_egress_profiles.careers.length >= 0
            "
          >
            <div class="profiles-container">
              <template
                v-for="career in row.item.faculties_careers_and_egress_profiles
                  .careers"
              >
                <div
                  class="profiles-content"
                  :key="'egress_profile' + row.item.id + '-' + career.id"
                >
                  <strong>
                    {{ career.name }}
                  </strong>
                  <li
                    v-for="egress_profile in career.egress_profiles"
                    :key="
                      'egress_profile' +
                      row.item.id +
                      '-' +
                      career.id +
                      '-' +
                      egress_profile.id
                    "
                  >
                    {{ egress_profile.professional_title }}
                  </li>
                </div>
              </template>
            </div>
            <div class="container-badge-profile d-flex">
              <h5>
                <b-badge
                  class="badge-profile"
                  variant="secondary"
                  :id="`egress_profile-badge-profile${row.item.id}`"
                >
                  {{
                    new Set(
                      row.item.faculties_careers_and_egress_profiles.careers.flatMap(
                        (item) =>
                          item.egress_profiles.map((profile) => profile.id)
                      )
                    ).size
                  }}
                </b-badge>
              </h5>
            </div>
            <b-popover
              :target="`egress_profile-badge-profile${row.item.id}`"
              triggers="hover"
              placement="left"
              v-if="
                row.item.faculties_careers_and_egress_profiles.careers.length >
                0
              "
            >
              <div class="badge-popover">
                <template
                  v-for="career in row.item
                    .faculties_careers_and_egress_profiles.careers"
                >
                  <div
                    class="profiles-content"
                    :key="'egress_profile' + row.item.id + '-' + career.id"
                  >
                    <strong>
                      {{ career.name }}
                    </strong>
                    <li
                      v-for="egress_profile in career.egress_profiles"
                      :key="
                        'egress_profile' +
                        row.item.id +
                        '-' +
                        career.id +
                        '-' +
                        egress_profile.id
                      "
                    >
                      {{ egress_profile.professional_title }}
                    </li>
                  </div>
                </template>
              </div>
            </b-popover>
          </template>
        </template>
        <template #cell(career)="row">
          <template
            v-if="
              row.item.faculties_careers_and_egress_profiles &&
              row.item.faculties_careers_and_egress_profiles.careers.length >= 0
            "
          >
            <div class="profiles-container">
              <template
                v-for="career in row.item.faculties_careers_and_egress_profiles
                  .careers"
              >
                <div
                  class="profiles-content"
                  :key="'career' + row.item.id + '-' + career.id"
                >
                  {{ career.name }}
                </div>
              </template>
            </div>
            <div class="container-badge-profile d-flex">
              <h5>
                <b-badge
                  class="badge-profile"
                  variant="secondary"
                  :id="`career-badge-profile${row.item.id}`"
                >
                  {{
                    row.item.faculties_careers_and_egress_profiles.careers
                      .length
                  }}
                </b-badge>
              </h5>
            </div>
            <b-popover
              :target="`career-badge-profile${row.item.id}`"
              triggers="hover"
              placement="left"
              v-if="
                row.item.faculties_careers_and_egress_profiles.careers.length >
                0
              "
            >
              <div class="badge-popover">
                <template
                  v-for="career in row.item
                    .faculties_careers_and_egress_profiles.careers"
                >
                  <div
                    class="profiles-content"
                    :key="'career' + row.item.id + '-' + career.id"
                  >
                    <strong>
                      {{ career.name }}
                    </strong>
                  </div>
                </template>
              </div>
            </b-popover>
          </template>
        </template>
        <template #cell(faculty)="row">
          <template
            v-if="
              row.item.faculties_careers_and_egress_profiles &&
              row.item.faculties_careers_and_egress_profiles.faculties.length >=
                0
            "
          >
            <div class="profiles-container">
              <template
                v-for="career in row.item.faculties_careers_and_egress_profiles
                  .faculties"
              >
                <div
                  class="profiles-content"
                  :key="'faculty' + row.item.id + '-' + career.id"
                >
                  {{ career.name }}
                </div>
              </template>
            </div>
            <div class="container-badge-profile d-flex">
              <h5>
                <b-badge
                  class="badge-profile"
                  variant="secondary"
                  :id="`faculty-badge-profile${row.item.id}`"
                >
                  {{
                    row.item.faculties_careers_and_egress_profiles.faculties
                      .length
                  }}
                </b-badge>
              </h5>
            </div>
            <b-popover
              :target="`faculty-badge-profile${row.item.id}`"
              triggers="hover"
              placement="left"
              v-if="
                row.item.faculties_careers_and_egress_profiles.faculties
                  .length > 0
              "
            >
              <div class="badge-popover">
                <template
                  v-for="faculty in row.item
                    .faculties_careers_and_egress_profiles.faculties"
                >
                  <div
                    class="profiles-content"
                    :key="'faculty' + row.item.id + '-' + faculty.id"
                  >
                    <strong>
                      {{ faculty.name }}
                    </strong>
                  </div>
                </template>
              </div>
            </b-popover>
          </template>
        </template>
        <template #cell(full_sentence)="row">
          <SentenceContainer
            :unique_id="'ra' + row.item.id"
            :Sentence="row.item"
          ></SentenceContainer>
        </template>
        <template #cell(competences)="row">
          <div
            v-for="competence in row.item.competences"
            :key="'id:' + row.item.id + ',competence:' + competence"
          >
            <SentenceContainer
              :unique_id="row.item.id + '-' + competence"
              :Sentence="getCompetence(competence)"
            ></SentenceContainer>
          </div>
        </template>
        <template v-if="allows_crud" #cell(actions)="row">
          <ButtonSmall
            :tooltip_text="`Editar ${$getVisibleNames(
              'teaching.basera',
              false,
              'RA Base'
            )}`"
            :click_button="() => editModal(row.item)"
            :class="``"
            :icon="'square'"
          ></ButtonSmall>
          <!-- <button-edit
            @click="editModal(row.item)"
            v-b-tooltip.v-secondary.noninteractive="
              `Editar ${$getVisibleNames('teaching.basera', false, 'RA Base')}`
            "
          ></button-edit> -->
          <ButtonSmall
            v-if="allows_crud && user.is_superuser"
            :click_button="() => askForDeleteRABase(row.item.id)"
            :tooltip_text="`Eliminar ${$getVisibleNames(
              'teaching.basera',
              false,
              'RA Base'
            )}`"
            :icon="'trash'"
          ></ButtonSmall>
          <!-- <button-delete
            v-if="allows_crud && user.is_superuser"
            @click="askForDeleteRABase(row.item.id)"
            v-b-tooltip.v-secondary.noninteractive="
              `Eliminar ${$getVisibleNames(
                'teaching.basera',
                false,
                'RA Base'
              )}`
            "
          ></button-delete> -->
          <b-modal
            :id="`edit-ra_base-modal-${row.item.id}`"
            :title="`Editar ${$getVisibleNames(
              'teaching.basera',
              false,
              'RA Base'
            )}`"
            size="xl"
            hide-footer
            @hide="
              () => {
                selected_competences = [];
              }
            "
          >
            <b-form-group
              :label="$getVisibleNames('mesh.competence', true, 'Competencia')"
              label-for="sort-by-select"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="sm"
              class="mb-4"
            >
              <v-select
                id="input-selected_competences"
                v-model="selected_competences"
                :options="competences"
                @change="forceRerender"
                :reduce="(competences) => competences.id"
                :placeholder="`${
                  competences.length > 0
                    ? String('Seleccione una o varias')
                    : String(
                        'No cuenta con ' +
                          $getVisibleNames(
                            'mesh.competence',
                            true,
                            'Competencia'
                          ).toLowerCase()
                      )
                }`"
                :dropdown-should-open="dropdownShouldOpen"
                multiple
                label="full_sentence"
                track-by="id"
                :selectable="
                  (option) => !selected_competences.includes(option.id)
                "
              >
                <template slot="selected-option" slot-scope="option">
                  <div
                    class="text-break"
                    @click="(e) => e.target.classList.toggle('text-break')"
                    v-b-tooltip.v-secondary="`${option.full_sentence}`"
                  >
                    {{ option.full_sentence }}
                  </div>
                </template>
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    No se encontraron resultados para: "<em>{{ search }}</em
                    >"
                  </template>
                </template>
              </v-select>
            </b-form-group>
            <RaBaseForm
              :key="componentKey"
              :RABase="row.item"
              :competences_ids="selected_competences"
              @updated="slotUpdateBaseRa"
            ></RaBaseForm>
          </b-modal>
        </template>
      </NewGenericBTable>
    </div>
    <b-modal
      :id="`new-ra_base-modal`"
      :hide-footer="true"
      :title="`Crear ${$getVisibleNames('teaching.basera', false, 'RA Base')}`"
      size="xl"
      @hide="
        () => {
          selected_competences = [];
        }
      "
    >
      <b-form-group
        :label="$getVisibleNames('mesh.competence', true, 'Competencia')"
        label-for="sort-by-select"
        label-cols-sm="2"
        label-align-sm="left"
        label-size="sm"
        class="mb-4"
      >
        <v-select
          id="input-selected_competences2"
          v-model="selected_competences"
          :options="competences"
          @change="forceRerender"
          :reduce="(competences) => competences.id"
          :selectable="(option) => !selected_competences.includes(option.id)"
          :placeholder="`${
            competences.length > 0
              ? String('Seleccione una o varias')
              : String(
                  'No cuenta con ' +
                    $getVisibleNames(
                      'mesh.competence',
                      true,
                      'Competencia'
                    ).toLowerCase()
                )
          }`"
          :dropdown-should-open="dropdownShouldOpen"
          multiple
          label="full_sentence"
          track-by="id"
        >
          <template slot="selected-option" slot-scope="option">
            <div
              class="text-break"
              @click="(e) => e.target.classList.toggle('text-break')"
              v-b-tooltip.v-secondary="`${option.full_sentence}`"
            >
              {{ option.full_sentence }}
            </div>
          </template>
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              No se encontraron resultados para: "<em>{{ search }}</em
              >"
            </template>
          </template>
        </v-select>
      </b-form-group>
      <RaBaseForm
        :key="componentKey"
        :competences_ids="selected_competences"
        @created="slotCreatedBaseRa"
      ></RaBaseForm>
    </b-modal>
  </div>
</template>

<script>
import "vue-multiselect/dist/vue-multiselect.min.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";
export default {
  name: "RaBaseView",
  components: {
    vSelect,
    RaBaseForm: () => import("@/components/reusable/RaBaseForm"),
    NewGenericBTable: () => import("@/components/reusable/NewGenericBTable"),
    // GenericBTable: () => import("@/components/reusable/GenericBTable"),
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
    AlternativeNameButton: () =>
      import("@/components/reusable/AlternativeNameButton"),
    GenericButtonDownload: () =>
      import("@/components/reusable/Buttons/GenericButtonDownload"),
    ButtonSmall: () =>
      import("@/components/reusable/Buttons/Small/ButtonSmall"),
    ButtonMedium: () =>
      import("@/components/reusable/Buttons/Medium/ButtonMedium"),
  },
  data() {
    return {
      input_search: "",
      multiple_search: [],
      selected_filter_id: 2,
      key_new_generic_table: 0,
      selected_competences: [],
      componentKey: 0,
      current_page: 1,
      disabled_button_excel: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      user_position: names.USER_POSITION,
      // ra_bases: names.RA_BASES,
      competences: names.COMPETENCES,
      profileCompetences: names.PROFILE_COMPETENCES,
      profile_cycles: names.PROFILE_CYCLES,
      faculties: names.FACULTIES,
      careers: names.CAREERS,
      egressProfiles: names.EGRESS_PROFILES,
    }),
    allows_crud() {
      if (this.user && this.user.is_superuser) {
        const has_permission = this.$hasPermissions("teaching.add_basera");
        if (has_permission) {
          this.$store.commit(names.MUTATE_VISIBILITY_CRUD, true);
          return has_permission;
        } else return false;
      } else {
        this.$store.commit(names.MUTATE_VISIBILITY_CRUD, false);
        return false;
      }
    },
    // breadcrumb_items() {
    //   return [
    //     { text: "Productos Curriculares", active: true },
    //     {
    //       text: this.$getVisibleNames("teaching.basera", false, "RA Base"),
    //       active: true,
    //     },
    //   ];
    // },
    search_verbs() {
      const multiple_search_verbs = this.multiple_search
        .filter((x) => x.value == 1)
        .map((x) => x.input_search);
      if (multiple_search_verbs.length > 0) return multiple_search_verbs;
      else return null;
    },
    search_names() {
      const multiple_search_names = this.multiple_search
        .filter((x) => x.value == 2)
        .map((x) => x.input_search);
      if (multiple_search_names.length > 0) return multiple_search_names;
      else return null;
    },
    faculty_ids() {
      const multiple_search_names = this.multiple_search
        .filter((x) => x.value == 3)
        .map((x) => x.input_search);
      const faculty_ids = this.faculties
        .filter(
          (a) =>
            multiple_search_names.filter((b) => this.$filtered(a.name, b))
              .length > 0
        )
        .map((x) => x.id);
      if (faculty_ids.length > 0) return faculty_ids;
      else if (multiple_search_names.length > 0 && faculty_ids.length == 0)
        return [0];
      else return null;
    },
    career_ids() {
      const multiple_search_names = this.multiple_search
        .filter((x) => x.value == 4)
        .map((x) => x.input_search);
      const career_ids = this.careers
        .filter(
          (a) =>
            multiple_search_names.filter((b) => this.$filtered(a.name, b))
              .length > 0
        )
        .map((x) => x.id);
      if (career_ids.length > 0) return career_ids;
      else if (multiple_search_names.length > 0 && career_ids.length == 0)
        return [0];
      else return null;
    },
    egress_profile_ids() {
      const multiple_search_names = this.multiple_search
        .filter((x) => x.value == 5)
        .map((x) => x.input_search);
      const egress_profile_ids = this.egressProfiles
        .filter(
          (a) =>
            multiple_search_names.filter((b) =>
              this.$filtered(a.professional_title, b)
            ).length > 0
        )
        .map((x) => x.id);
      if (egress_profile_ids.length > 0) return egress_profile_ids;
      else if (
        multiple_search_names.length > 0 &&
        egress_profile_ids.length == 0
      )
        return [0];
      else return null;
    },
    competence_ids() {
      const multiple_search_names = this.multiple_search
        .filter((x) => x.value == 6)
        .map((x) => x.input_search);
      const competence_ids = this.competences
        .filter(
          (a) =>
            multiple_search_names.filter((b) =>
              this.$filtered(a.full_sentence, b)
            ).length > 0
        )
        .map((x) => x.id);
      if (competence_ids.length > 0) return competence_ids;
      else if (multiple_search_names.length > 0 && competence_ids.length == 0)
        return [0];
      else return null;
    },
    filterOptions() {
      let list = [
        {
          value: 1,
          name: "Verbo",
        },
        {
          value: 2,
          name: this.$getVisibleNames("teaching.basera", true, "RA Bases"),
        },
        {
          value: 3,
          name: this.$getVisibleNames("mesh.faculty", true, "Facultades"),
        },
        {
          value: 4,
          name: this.$getVisibleNames("mesh.career", true, "Programas"),
        },
        {
          value: 5,
          name: this.$getVisibleNames(
            "mesh.egressprofile",
            true,
            "perfiles de egreso"
          ),
        },
        {
          value: 6,
          name: this.$getVisibleNames("mesh.competence", true, "Competencias"),
        },
      ];
      return list;
    },
    pre_filter() {
      let partial_url = [];
      if (this.search_verbs != null)
        partial_url.push("action__in=" + this.search_verbs.join(","));
      if (this.search_names != null) {
        this.search_names.forEach((x) => {
          partial_url.push("full_sentence__icontains=" + x);
        });
      }
      if (this.faculty_ids != null)
        partial_url.push(
          "competences__egress_profiles__career__faculty__id__in=" +
            this.faculty_ids.join(",")
        );
      if (this.career_ids != null)
        partial_url.push(
          "competences__egress_profiles__career__id__in=" +
            this.career_ids.join(",")
        );
      if (this.egress_profile_ids != null)
        partial_url.push(
          "competences__egress_profiles__id__in=" +
            this.egress_profile_ids.join(",")
        );
      if (this.competence_ids != null)
        partial_url.push(
          "competences__id__in=" + this.competence_ids.join(",")
        );
      return partial_url.join("&");
    },
    ra_bases_fields() {
      return [
        {
          key: "full_sentence",
          label: String(
            this.$getVisibleNames("teaching.basera", true, "RA Bases")
          ),
          sortable: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "faculty",
          label: String(
            this.$getVisibleNames("mesh.faculty", true, "Facultades")
          ),
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "career",
          label: String(
            this.$getVisibleNames("mesh.career", true, "Programas")
          ),
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "egress_profile",
          label: String(
            this.$getVisibleNames(
              "mesh.egressprofile",
              true,
              "Perfiles de egreso"
            )
          ),
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "competences",
          label: String(
            this.$getVisibleNames("mesh.competence", true, "Competencias")
          ),
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-left",
          display_column: true,
        },
        {
          key: "actions",
          label: "",
          thStyle: { background: "var(--primary-color) !important" },
          display_column: true,
          thClass: "noprint",
          tdClass: "noprint",
        },
      ];
    },
  },
  methods: {
    dropdownShouldOpen(VueSelect) {
      if (this.competences.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    editModal(item) {
      this.selected_competences = item.competences;
      this.$bvModal.show(`edit-ra_base-modal-${item.id}`);
    },
    getCompetence(competence_id) {
      const competence = this.competences.find((x) => x.id == competence_id);
      return competence ? competence : null;
    },
    forceRerender() {
      this.componentKey += 1;
    },
    askForDeleteRABase(ra_base_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "teaching.basera",
          false,
          "RA Base"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(names.DELETE_RA_BASE, ra_base_id).then(() => {
            this.key_new_generic_table++;
            toast(
              String(
                this.$getVisibleNames("teaching.basera", false, "RA Base") +
                  " eliminado."
              )
            );
          });
        }
      });
    },
    slotCreatedBaseRa() {
      this.key_new_generic_table++;
      this.$bvModal.hide(`new-ra_base-modal`);
    },
    slotUpdateBaseRa(base_ra) {
      this.key_new_generic_table++;
      this.$bvModal.hide(`edit-ra_base-modal-${base_ra.id}`);
    },
    slotUpdateCurrentPage(current_page) {
      this.current_page = current_page;
    },
    DownloadExcel() {
      this.disabled_button_excel = true;
      this.$restful
        .Post(`/teaching/excel_report_base_ra_repository/`, {
          base_ra_action_search_terms: this.search_verbs,
          base_ra_search_terms: this.search_names,
          faculty_ids: this.faculty_ids,
          career_ids: this.career_ids,
          egress_profile_ids: this.egress_profile_ids,
          competence_ids: this.competence_ids,
        })
        .then((response) => {
          // URL + `/teaching/word_report_egress_profile_output/?egress_profile=${this.egress_profile_id}`,
          if (response && response.file_url) {
            this.disabled_button_excel = false;
            window.open(response.file_url, "_blank");
          }
        });
    },
    deleteTag(index) {
      this.multiple_search.splice(index, 1);
      this.key_new_generic_table++;
    },
    addSearchTag() {
      if (this.input_search != "" && this.selected_filter_id != null) {
        const selected_filter = this.filterOptions.find(
          (x) => x.value == this.selected_filter_id
        );
        if (selected_filter)
          this.multiple_search.push({
            name: selected_filter.name + ": " + this.input_search,
            input_search: this.input_search,
            value: selected_filter.value,
          });
        this.input_search = "";
        this.key_new_generic_table++;
      }
    },
  },
  watch: {
    pre_filter() {
      this.current_page = 1;
      this.key_new_generic_table++;
    },
  },
  created() {
    this.$store.dispatch(names.FETCH_TAXONOMIES);
    this.$store.dispatch(names.FETCH_COMPETENCES);
    this.$store.dispatch(names.FETCH_EGRESS_PROFILES);
    this.$store.dispatch(names.FETCH_CAREERS);
    this.$store.dispatch(names.FETCH_FACULTIES);
  },
};
</script>

<style scoped>
th {
  background-color: var(--primary-color);
  border-radius: 6px;
  box-shadow: 1px 1px 3px rgb(72, 72, 72);
}

.title-header {
  padding: 0;
  margin-top: auto;
}
.search-ra_base {
  max-width: 50rem;
  margin-left: auto;
  margin-right: 18.5%;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}
.edit-section {
  margin-top: -2%;
}
.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  margin-left: 2.8em !important;
}
.breadcrumb-item.active {
  color: #2f9ae1;
}
.breadcrumb > li + li:before {
  color: #2f9ae1;
  content: "❯ ";
  padding: 0;
  padding-right: 0.65em;
}
.profiles-container {
  display: none;
  /* display: flex !important;
  flex-direction: column; */
}
.badge-popover {
  overflow: auto;
  max-height: 350px;
}
.container-badge-profile {
  justify-content: center;
}
.profiles-content {
  border-bottom: 0.1px solid var(--secondary-color);
  margin: 0 !important;
  margin-top: 0.4em !important;
  min-height: fit-content;
  min-width: fit-content;
}
.profiles-content:last-child {
  border-bottom: none;
}
.text-break {
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}
@media print {
  .badge {
    border: none;
  }
  .profiles-container {
    display: flex;
    flex-direction: column;
  }
  .badge-profile {
    display: none;
  }
}
.tag-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
}
.style-tag {
  background-color: var(--primary-color);
  color: white;
  max-width: fit-content;
  border-radius: 3px;
  margin: 0.1em 0.1em;
  padding: 1px 4px;
  flex-grow: 1;
  font-size: var(--thirth-font-size);
  /* text-align: center; */
}
</style>